import {useState, useEffect} from 'react';



export default function useLocalStorage(key, initialValue) {
    const storedValue = localStorage.getItem(key);
    const [value, setValue] = useState(storedValue || initialValue);

    useEffect(() => localStorage.setItem(key, storedValue || initialValue), []);

    function updateValue(newValue) {
        localStorage.setItem(key, newValue);
        setValue(newValue);
    }

    return [value, updateValue];
}