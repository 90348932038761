import React, {useState, useContext} from 'react';

import ajaxRequest from '../../assets/functions/ajaxRequest';

import Context from './context.jsx';


export default function EmailSignupForm({header, extraClasses, setEmailSignupCompleted}) {
    const {authenticityToken} = useContext(Context);

    const [email, setEmail] = useState('');
    const [signedUp, setSignedUp] = useState(false);

    function handleSubmit(event) {
        event.preventDefault();
        if (email.length === 0) return;

        ajaxRequest('POST', '/email_signup', {
            headers: [{'X-CSRF-Token': authenticityToken}],
            body: {email},
            json: true
        }).then(response => {
            setSignedUp(true);
            setEmailSignupCompleted(true);
        }).catch(response => console.log(response));
    }

    return signedUp ? <></> : <form onSubmit={handleSubmit} className={`w-full md:w-1/2 mx-auto text-dark ${extraClasses}`}>
        { header ? <h3 className="mb-1 text-lg text-dark md:text-xl lgplus:text-2xl font-bold">
            {header}
        </h3> : null }

        <p className="mb-2 text-base font-primary">We will email you when new exciting offers are available. We will never share your email with third parties</p>

        <label className="block text-lg text-left text-dark font-semibold" htmlFor="email">Email</label>
        <input className="block w-full p-2 mb-4 border-2 border-light rounded focus:border-primary text-xl outline-none"
            value={email}
            onChange={event => setEmail(event.target.value)}
            type="email"
            id="email" />

        <button className="w-48 px-2 py-1.5 border rounded-full bg-primary text-white text-center transition duration-300 hover:shadow">
            Submit
        </button>
    </form>;
}