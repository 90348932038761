import React, {useContext, useEffect, useState} from "react";

import Context from './context.jsx';

import useLocalStorage from '../../hooks/useLocalStorage.jsx';

import OfferListItem from "./offerListItem.jsx";
import EmailSignupForm from "./emailSignUpForm.jsx";
import LoadingSpinner from "../shared/utility/loadingSpinner.jsx";
import InfoIcon from "../shared/icons/infoIcon.jsx";
import SearchIcon from "../shared/icons/searchIcon.jsx";



export default function OffersPage({setParallaxVideo, setParallaxInnerContent, setParallaxVideoPoster, setTutorialModalOpen}) {
    const {offers, retailers, brands, loadingOffers, initialOffersLoaded, selectedState} = useContext(Context);

    const [typeFilter, setTypeFilter] = useState('all');
    const [retailerFilter, setRetailerFilter] = useState(false);
    const [brandFilter, setBrandFilter] = useState(false);
    const [sortBy, setSortBy] = useState('new');
	const [searchTerm, setSearchTerm] = useState('');
    const [filteredOffers, setFilteredOffers] = useState(offers);

    const [emailSignupCompleted, setEmailSignupCompleted] = useLocalStorage('emailSignupCompleted', 'false');

    useEffect( () => {
        const parallaxInnerContent = <div className="container w-full px-4 z-10">
            <h1 className="w-full mb-2 md:mb-5 lgplus:mb-10 text-3xl md:text-4xl text-center text-white font-bold text-shadow">Save on your favorite adult beverages</h1>
            
            <div className="flex justify-between items-center w-full md:w-1/2 mx-auto px-4 py-3 bg-white rounded-lg shadow">
                <input className="w-full outline-none" type="text" placeholder="Search beer, wine, and spirit offers" onChange={ event => setSearchTerm(event.target.value) } />

                <SearchIcon />
            </div>
        </div>;

		setParallaxVideo('/assets/videos/shoppingForAlcohol1.mp4');
		setParallaxVideoPoster('/assets/images/offers/shoppingForAlcohol1PosterImage.jpg');
        setParallaxInnerContent(parallaxInnerContent);

		return () => {
            setParallaxVideo(false);
            setParallaxVideoPoster(false);
            setParallaxInnerContent(false);
        }
	}, [retailers] );

    useEffect (() => {
        setFilteredOffers(sortAndFilterOffers());
    }, [offers, typeFilter, retailerFilter, brandFilter, sortBy, searchTerm]);

    function handleRetailerFilterChange(retailer) {
        setBrandFilter(false);
        if (String(retailerFilter).toLowerCase() === retailer.name.toLowerCase()) setRetailerFilter(false);
        else setRetailerFilter(retailer.name.toLowerCase());
    }

    function handleBrandFilterChange(brand) {
        setRetailerFilter(false);
        if (String(brandFilter).toLowerCase() === brand.name.toLowerCase()) setBrandFilter(false);
        else setBrandFilter(brand.name.toLowerCase());
    }

    const sortAndFilterOffers = () => {
		return offers.filter( offer => {
			if (typeFilter === 'all') return true;
	
            var match = offer.required_groups[0].required_products.reduce( (match, requiredProduct) => {
				if ( requiredProduct.product_type.toLowerCase() === typeFilter.toLowerCase() ) match = true;	
				return match;
			}, false );

			return match;
        } ).filter( offer => {
			let match = searchTerm === '' ? true : false;

			offer.required_groups[0].required_products.forEach( requiredProduct => {
                if (requiredProduct.name.toLowerCase().includes(searchTerm.toLowerCase())) match = true;
			} );

			return match;
		} ).filter( offer => {
            if (retailerFilter) {
                return offer.retailers.filter(retailer => retailer.name.toLowerCase() === retailerFilter).length > 0;
            } else if (brandFilter) {
                return offer.brand && offer.brand.name.toLowerCase() === brandFilter;
            } else return true;
        } ).sort( (a, b) => {
            switch (sortBy) {
                case 'new':
                    return  Math.abs( new Date( a.start_date ).getTime() - new Date().getTime() ) - Math.abs( new Date( b.start_date ).getTime() - new Date().getTime() );
                case 'endingSoon':
                    return new Date(a.end_date) - new Date(b.end_date);
                case 'valueDesc':
                    return b.discount_cents - a.discount_cents;
                case 'valueAsc':
                    return a.discount_cents - b.discount_cents;
                default:
                    return new Date(b.created_at) - new Date(a.created_at);
            }
        } ).sort( (a, b) => {
            return b.featured - a.featured;
        } );
    }

    const productTypes = offers.reduce((productTypes, offer) => {
        const productType = offer.required_groups[0].required_products[0].product_type.toLowerCase();
        productTypes[productType] = productType;
        return productTypes;
    }, {});

    return <div className="container mx-auto my-2 md:my-4">
        <div className="flex flex-wrap flex-col sm:flex-row justify-between mb-2 md:mb-4 pb-2 md:pr-10 border-b border-light">
            <div className="absolute top-0 right-0 my-4 md:my-4 mx-4" onClick={() => setTutorialModalOpen(true)}>
                <InfoIcon />
            </div>
            <div className="space-x-1 sm:space-x-2 space-y-1 sm:space-y-0">

                { productTypes.beer ? <button className={`px-2 py-1.5 border border-light rounded-full hover:shadow ${typeFilter === 'beer' ? ' bg-primary text-white border-primary' : ''}`}
                    onClick={() => setTypeFilter(typeFilter === 'beer' ? 'all' : 'beer')}>
                    Beer
                </button> : <></> }

                { productTypes.wine ? <button className={`px-2 py-1.5 border border-light rounded-full hover:shadow ${typeFilter === 'wine' ? ' bg-primary text-white border-primary' : ''}`}
                    onClick={() => setTypeFilter(typeFilter === 'wine' ? 'all' : 'wine')}>
                    Wine
                </button> : <></> }

                { productTypes.spirit ? <button className={`px-2 py-1.5 border border-light rounded-full hover:shadow ${typeFilter === 'spirits' ? ' bg-primary text-white border-primary' : ''}`}
                    onClick={() => setTypeFilter(typeFilter === 'spirit' ? 'all' : 'spirit')}>
                    Spirits
                </button> : <></> }

            </div>

            <div className="sm:mt-0 space-x-1 sm:space-x-2 space-y-1 sm:space-y-0">
                <button className={`px-2 py-1.5 border border-light rounded-full hover:shadow ${sortBy === 'new' ? ' bg-primary text-white border-primary' : ''}`}
                    onClick={() => setSortBy('new')}>
                    New
                </button>

                <button className={`px-2 py-1.5 border border-light rounded-full hover:shadow ${sortBy === 'endingSoon' ? ' bg-primary text-white border-primary' : ''}`}
                    onClick={() => setSortBy('endingSoon')}>
                    Ending Soon
                </button>

                <button className={`px-2 py-1.5 border border-light rounded-full hover:shadow ${sortBy === 'valueDesc' ? ' bg-primary text-white border-primary' : ''}`}
                    onClick={() => setSortBy('valueDesc')}>
                    High to Low
                </button>
                
                <button className={`px-2 py-1.5 border border-light rounded-full hover:shadow ${sortBy === 'valueAsc' ? ' bg-primary text-white border-primary' : ''}`}
                    onClick={() => setSortBy('valueAsc')}>
                    Low to High
                </button>
            </div>
        </div>

        { retailers.length || brands.length ? <ul className="flex flex-wrap justify-center items-center gap-2 w-full mb-2 md:mb-4 mx-auto">

            {retailers.map( retailer => {
                return <a key={`retailerFilter_${retailer.name}`}
                    className={`flex items-center justify-center h-24 w-24 rounded-full cursor-pointer ${String(retailerFilter) === retailer.name.toLowerCase() ? 'bg-primary' : 'bg-white'}`}
                    onClick={() => handleRetailerFilterChange(retailer)}
                    style={ String(retailerFilter) !== retailer.name.toLowerCase() ?
                        {boxShadow: 'rgba(17, 17, 26, 0.05) 0px 4px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px'} :
                        {boxShadow: 'rgba(17, 17, 26, 0.05) 0px 2px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px'} }>
                    <img className="w-14" src={retailer.secondary_logo_image_url} />
                </a>;
            } )}

            {brands.map( brand => {
                return <a key={`retailerFilter_${brand.name}`}
                    className={`flex items-center justify-center h-24 w-24 rounded-full cursor-pointer ${String(brandFilter) === brand.name.toLowerCase() ? 'bg-primary' : 'bg-white'}`}
                    onClick={() => handleBrandFilterChange(brand)}
                    style={ String(brandFilter) !== brand.name.toLowerCase() ?
                        {boxShadow: 'rgba(17, 17, 26, 0.05) 0px 4px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px'} :
                        {boxShadow: 'rgba(17, 17, 26, 0.05) 0px 2px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px'} }>
                    <img className="w-14" src={brand.logo_image_url} />
                </a>;
            } )}

        </ul> : <></> }

        { filteredOffers.length ? <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lgplus:grid-cols-5 justify-center gap-2 md:gap-4">

            { filteredOffers.map( offer => <OfferListItem key={`offerListItem_${offer.id}`} offer={offer} /> ) }

        </ul> :  null }

        { loadingOffers ? <LoadingSpinner size="12" color="text-primary" extraClasses="mx-auto" wrapperClasses="py-3" /> : null }

        { loadingOffers || filteredOffers.length ? null : initialOffersLoaded ? <>
            <p className="mt-4 text-2xl text-dark text-center font-bold">No offers available in {selectedState.name}</p>
            <p className="text-center mb-2">Come back in a few weeks
                { JSON.parse(emailSignupCompleted) ? <span>. We will email about upcoming offers</span> :
                    <span> or sign up to find out when new offers are available</span> }
            </p>

            { JSON.parse(emailSignupCompleted) ? null : <EmailSignupForm extraClasses="mb-4 text-center"
                setEmailSignupCompleted={setEmailSignupCompleted} /> }
        </> : null }
    </div>;
}