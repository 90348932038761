import React from 'react';
import {Link} from 'react-router-dom';

import formatMoney from '../../assets/functions/formatMoney.js';
import StarIcon from '../shared/icons/starIcon.jsx';



export default function OfferListItem({offer}) {

    return <li key={offer.id} className="relative p-2 md:p-4 shadow">
        <Link to={`/offer/${offer.id}`} className="flex flex-col h-full">
            <img className="h-40 mx-auto" src={offer.image_url} alt={offer.name} />

            <div className="flex flex-col flex-grow">
                <div className="flex-grow mt-2">
                    <h3 className="text-xl font-semibold text-dark">Buy {offer.required_groups[0].quantity}, get {formatMoney(offer.discount_cents)} back</h3>
                    <p className="mt-2 text-sm font-secondary">{offer.name}</p>
                </div>

                <button className="shrink-0 mt-2 px-2 py-1.5 border rounded-full bg-primary text-white text-center transition duration-300 hover:shadow">
                    View Offer
                </button>
            </div>

            { offer.featured ? <div className="absolute top-4 right-4 px-2 py-1.5 border border-primary rounded-full bg-white text-primary">
                Featured <StarIcon />
            </div> : <></> }
        </Link>
    </li>;
}
