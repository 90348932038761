import React from 'react'

export default function SearchIcon({extraClasses, size = '6'}) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={`inline-block h-${size} w-${size} ${extraClasses}`}
      fill='none'
      viewBox='0 0 24 24'
      stroke='currentColor'>
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z'
      />
    </svg>
  )
}
